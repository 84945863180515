<template>
	<div v-if="showAlerts" class="page-width alerts_container">
		<div class="alert_container">
			<div v-if="unsynced_suppliers.length > 0" class="alert">
				<div class="alert_title_container">
					<p class="alert_title">Suppliers are not synchronized</p>
				</div>
				<div class="sub_container">
					<p class="sub_container_title">UNSYNCED SUPPLIERS:</p>
					<div class="router_links">
						<div @click="openSupplierModal(unsynced_supplier)" v-for="unsynced_supplier in unsynced_suppliers" class="router_link">
							{{ unsynced_supplier.username }}
						</div>
					</div>
				</div>
			</div>
			<div v-if="unfull_booked_suppliers.length > 0" class="alert">
				<div class="alert_title_container">
					<p class="alert_title">Suppliers are not fully booked</p>
				</div>
				<div class="sub_container">
					<p class="sub_container_title">UNFULL BOOKED SUPPLIERS:</p>
					<div class="router_links">
						<div @click="openUnfullBookedSupplier(unfull_booked_supplier)" v-for="unfull_booked_supplier in unfull_booked_suppliers" class="router_link">
							{{ unfull_booked_supplier.username }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="alert_container">
			<div v-if="!isShuffleCallsValid" class="alert">
				<div class="alert_title_container">
					<p class="alert_title">Invalid calls</p>
					<p>SHUFFLE</p>
				</div>
				<div v-for="supplier in shuffle_suppliers">
					<div v-if="!isValidShuffleCall(supplier)" class="valid_call_container">
						<a class="invalid_call_indicator"></a>
						<p>{{ supplier.username }} - {{ supplier.outsource_class }} - (SUPPLIER ID: {{ supplier.id }})</p>
					</div>
				</div>
			</div>
			<div v-if="!isStargateCallsValid" class="alert">
				<div class="alert_title_container">
					<p class="alert_title">Invalid calls</p>
					<p>STARGATE</p>
				</div>
				<div v-for="supplier in stargate_suppliers">
					<div v-if="!isValidStargateCall(supplier)" class="valid_call_container">
						<a class="invalid_call_indicator"></a>
						<p>{{ supplier.username }} - {{ supplier.outsource_class }} - (SUPPLIER ID: {{ supplier.id }})</p>
					</div>
				</div>
			</div>
		</div>
		<div v-if="suppliers_with_duplicate_slots.length > 0" class="alert_container">
			<div class="alert">
				<div class="alert_title_container">
					<p class="alert_title">Suppliers with Duplicate Slots</p>
					<p>SHUFFLE</p>
				</div>
				<div v-for="supplier in suppliers_with_duplicate_slots">
					<div class="valid_call_container">
						<a class="invalid_call_indicator"></a>
						<p>{{ supplier.username }} - {{ supplier.outsource_class }} - (SUPPLIER ID: {{ supplier.id }})</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	export default {
		name: 'alerts',
		data(){
			return {
				unsynced_suppliers: [],
				unfull_booked_suppliers: [],
				stargate_suppliers: [],
				shuffle_suppliers: [],
				suppliers_with_duplicate_slots: [],
				is_loaded_stargate_suppliers: false,
				is_loaded_shuffle_suppliers: false,
			}
		},
		created(){
			this.getAlerts()
		},
		computed: {
			showAlerts(){
				if (this.unsynced_suppliers.length > 0){
					return true
				}
				if(this.unfull_booked_suppliers.length > 0){
					return true
				}
				if(this.is_loaded_stargate_suppliers && !this.isStargateCallsValid){
					return true
				}
				if(this.is_loaded_shuffle_suppliers && !this.isShuffleCallsValid){
					return true
				}
				return false
			},
			isStargateCallsValid(){
				for(let i = 0; i < this.stargate_suppliers.length; i++){
					if(!this.isValidStargateCall(this.stargate_suppliers[i])){
						return false
					}
				}
				return true	
			},
			isShuffleCallsValid(){
				for(let i = 0; i < this.shuffle_suppliers.length; i++){
					if(!this.isValidShuffleCall(this.shuffle_suppliers[i])){
						return false
					}
				}
				return true
			}
		},
		methods: {
			getAlerts(){
				this.$network_utils.getShuffleSuppliers({ status: 'active', is_synced: false }).then(response => {
					this.unsynced_suppliers = response
				})
				this.$network_utils.getShuffleSuppliers({ status: 'active', is_fully_booked: false }).then(response => {
					this.unfull_booked_suppliers = response
				})
				this.$network_utils.getStargateSuppliers({ status: 'active' }).then(response => {
					this.stargate_suppliers = response
					this.is_loaded_stargate_suppliers = true
				})	
				this.$network_utils.getShuffleSuppliers({ status: 'active' }).then(response => {
					this.shuffle_suppliers = response
					this.getSuppliersWithDuplicateSlots()
					this.is_loaded_shuffle_suppliers = true
				})
			},
			getSuppliersWithDuplicateSlots(){
				let requests = []
				this.shuffle_suppliers.forEach(supplier => {
					this.$network_utils.getSlots({ supplier_id: supplier.id, statuses: 'active' }).then(response => {
						let position_ids = response.map(slot => slot.position.id)
						// https://wsvincent.com/javascript-remove-duplicates-array/
						let unique_position_ids = position_ids.filter((position_id, index) => position_ids.indexOf(position_id) === index)
						// Check if there are duplicate positions ids
						if(position_ids.sort().join(',') != unique_position_ids.sort().join(',')){
							this.suppliers_with_duplicate_slots.push(supplier)
						}
					})
				})
			},
			isValidShuffleCall(supplier){
				if(supplier.last_valid_date == undefined){
					return false
				}
				let call_date = new Date(supplier.last_valid_date).getTime()
				let hour = 1000 * 60 * 60
				return (new Date().getTime() - call_date) < hour
			},
			isValidStargateCall(supplier){
				if(supplier.last_valid_date == undefined){
					return false
				}
				let call_date = new Date(supplier.last_valid_date).getTime()
				let half_hour = 1000 * 60 * 30
				return (new Date().getTime() - call_date) < half_hour
			},
			openSupplierModal(supplier){
				this.createModal({
					designator: 'supplier-modal',
					component_name: 'SupplierForm',
					supplier,
					modal_width: 1300,
					is_center_modal: true,
					modal_title: `${supplier.username} - SUPPLIER ID: ${supplier.id} (SHUFFLE)`
				})
			},
			openUnfullBookedSupplier(supplier){
				this.createModal({
					designator: 'supplier-modal',
					component_name: 'SupplierForm',
					supplier,
					modal_width: 1300,
					is_center_modal: true,
					modal_title: `${supplier.username} - SUPPLIER ID: ${supplier.id} Unfull`
				})
			},
			...mapMutations('modals', ['createModal'])
		}
	}
</script>
<style scoped>
	.alerts_container {
		margin-bottom: 40px;
	}
	.alert_container {
		display: flex;
		justify-content: space-between;
		max-width: 100%;
		margin: 0 auto;
	}
	.alert {
		width: calc(50% - 15px);
		padding: 25px;
		padding-top: 0;
		border: 1px solid rgba(0, 0, 0, 0.1);
		background-color: white;
		box-shadow: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
			0 6px 22px 4px rgba(7, 48, 114, 0.12),
			0 6px 10px -4px rgba(14, 13, 26, 0.12);
		border-radius: 13px;
		margin: 30px 0;
		margin-bottom: 0;
	}
	.alert_title_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: 10px;
		margin-bottom: 0;
		padding: 3px 0;
		border-radius: 30px;
	}
	.alert_title {
		font-weight: 900;
		font-size: 22px;
	}
	.alert_sub_title {
		text-transform: uppercase;
		font-size: 13px;
	}
	.sub_container{
		margin-top: 10px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);	
	}
	.router_links{
		display: flex;
		flex-wrap: wrap;
	}
	.router_link {
		cursor: pointer;
		margin-top: 15px;
		padding: 5px 20px;
		margin-right: 20px;
		border-radius: 30px;
		color: black;
		background-color: #f2f2f2;
		border: 1px solid red;
	}
	.sub_container_title {
		margin-top: 10px;
	}
	.valid_call_container {
		margin-top: 10px;
		display: flex;
		align-items: center;
	}
	.valid_call_container p {
		margin-left: 10px;
	}
	.valid_call_indicator{
		border-radius: 50%;
		width: 10px;
		height: 10px;
		background-color: green;
	}
	.invalid_call_indicator {
		border-radius: 50%;
		width: 10px;
		height: 10px;
		background-color: red;
	}
</style>